import { isFalseCondition, isTrueCondition } from '~/schemas/components/common/helpers.js'
import inlineSwitcher from '~/schemas/components/common/inline-switcher.js'
import estateAgent from '~/schemas/components/instruction/estate-agent.js'
import accessBlock from '~/schemas/components/instruction/purchase/access-block.js'
import alterationsBlock from '~/schemas/components/instruction/purchase/alterations-block.js'
import flatsBlock from '~/schemas/components/instruction/purchase/flats-block.js'

export default function (parentPath) {
  return [
    {
      $cmp: 'ElForm',
      props: { labelPosition: 'top', size: 'large', class: 'pl-7 pr-6 pt-6' },
      children: [
        {
          $cmp: 'ElRow',
          props: { gutter: 20 },
          children: [
            {
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [
                {
                  $cmp: 'el-form-item',
                  props: { required: true },
                  children: [
                    {
                      $el: 'div',
                      children: '£ Price you have agreed',
                      attrs: {
                        class: 'text-sm font-[500] text-black required-label',
                        required: true,
                      },
                    },
                    {
                      $el: 'div',
                      children: 'Please do not use commas when writing the price',
                      attrs: {
                        class: 'text-sm text-black',
                      },
                    },
                    {
                      $formkit: 'el-input-money',
                      name: `${parentPath}.agreed_selling_price`,
                      validation: 'required',
                      validationMessages: {
                        required: 'This field is required',
                      },
                      class: 'max-w-[340px] mb-6',
                    },
                  ],
                },
              ],
            },
            {
              $cmp: 'ElCol',
              props: { span: 24, class: 'mt-4' },
              children: [...inlineSwitcher(parentPath + '.is_new_build', 'Is the purchase property a new build?')],
            },
            {
              if: isTrueCondition(`${parentPath}.is_new_build`),
              $cmp: 'ElRow',
              props: { gutter: 20, class: 'pl-8 pb-4' },
              children: [
                {
                  $cmp: 'ElCol',
                  props: { xs: 24 },
                  children: [
                    {
                      $cmp: 'el-form-item',
                      props: { label: 'Address Line 1', required: true },
                      children: [
                        {
                          $formkit: 'el-input',
                          props: {
                            class: 'w-full',
                          },
                          name: `${parentPath}.new_build_address.address_line_1`,
                          validation: 'required',
                          validationMessages: {
                            required: 'This field is required',
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  $cmp: 'ElCol',
                  props: { xs: 24 },
                  children: [
                    {
                      $cmp: 'el-form-item',
                      props: { label: 'Address Line 2', required: true },
                      children: [
                        {
                          $formkit: 'el-input',
                          props: {
                            class: 'w-full',
                          },
                          name: `${parentPath}.new_build_address.address_line_2`,
                          validation: 'required',
                          validationMessages: {
                            required: 'This field is required',
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  $cmp: 'ElCol',
                  props: { xs: 24 },
                  children: [
                    {
                      $cmp: 'el-form-item',
                      props: { label: 'Address Line 3' },
                      children: [
                        {
                          $formkit: 'el-input',
                          props: {
                            class: 'w-full',
                          },
                          name: `${parentPath}.new_build_address.address_line_3`,
                        },
                      ],
                    },
                  ],
                },
                {
                  $cmp: 'ElCol',
                  props: { xs: 24, sm: 12 },
                  children: [
                    {
                      $cmp: 'el-form-item',
                      props: { label: 'Postcode', required: true },
                      children: [
                        {
                          $formkit: 'el-input',
                          props: {
                            class: 'w-full',
                          },
                          name: `${parentPath}.new_build_address.postcode`,
                          validation: 'required',
                          validationMessages: {
                            required: 'This field is required',
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  $cmp: 'ElCol',
                  props: { xs: 24, sm: 12 },
                  children: [
                    {
                      $cmp: 'el-form-item',
                      props: { label: 'City', required: true },
                      children: [
                        {
                          $formkit: 'el-input',
                          props: {
                            class: 'w-full mb-8',
                          },
                          name: `${parentPath}.new_build_address.city`,
                          validation: 'required',
                          validationMessages: {
                            required: 'This field is required',
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              if: isFalseCondition(`${parentPath}.is_new_build`),
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [...inlineSwitcher(parentPath + '.known_address', 'Do you know the address of the property that you are buying?')],
            },
            {
              if: isTrueCondition(parentPath + '.known_address'),
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [
                {
                  $cmp: 'ElFormItem',
                  props: { label: 'Search Address', required: true },
                  children: [
                    {
                      $formkit: 'address-form-autocomplete',
                      name: `${parentPath}.address`,
                      validation: 'required',
                      validationMessages: {
                        required: 'This field is required',
                      },
                    },
                  ],
                },
              ],
            },
            {
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [
                {
                  $cmp: 'ElFormItem',
                  props: {
                    label: 'Type of the Property You are Buying',
                    class: 'font-bold',
                  },
                  children: [
                    {
                      $formkit: 'el-radio-group',
                      name: parentPath + '.property_type',
                      options: [
                        {
                          label: 'Freehold',
                          value: 'freehold',
                        },
                        {
                          label: 'Leasehold',
                          value: 'leasehold',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [...inlineSwitcher(parentPath + '.using_money_from_abroad', 'Are you using a part or full amount of money from abroad?', '')],
            },
            {
              if: isTrueCondition(parentPath + '.using_money_from_abroad'),
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [
                {
                  $el: 'div',
                  children: [
                    {
                      $el: 'div',
                      attrs: {
                        class: 'text-red-500 font-bold mb-8',
                      },
                      children: 'Sorry, our UK Anti-Money Laundering Compliance Team cannot accept money from outside of the UK.',
                    },
                    {
                      $cmp: 'el-form-item',
                      props: { required: true },
                      children: [
                        {
                          $cmp: 'el-checkbox',
                          attrs: {},
                          name: parentPath + '.using_money_from_abroad_forbidden_to_confirm',
                          validation: 'required',
                          validationMessages: {
                            required: 'This field is required',
                          },
                        },
                      ],
                      attrs: {
                        class: 'hidden',
                      },
                    },
                  ],
                },
              ],
            },
            {
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [...inlineSwitcher(parentPath + '.buying_with_mortgage', 'Are you buying with a mortgage?', '')],
            },
            // {
            //   if: `$formData.data.${parentPath + '.buying_with_mortgage'} !== true`,
            //   $cmp: 'ElCol',
            //   props: { span: 24 },
            //   children: [
            //     {
            //       $formkit: 'el-checkbox-group',
            //       attrs: {
            //         class: 'flex flex-inline required-label',
            //       },
            //       props: { required: true },
            //       name: `${parentPath}.buying_with_cash`,
            //       options: [{ label: "I'm a cashbuyer", value: 'Yes' }],
            //       validation: 'required',
            //       validationMessages: {
            //         required: 'This field is required',
            //       },
            //     },
            //   ],
            // },
            {
              if: `$formData.data.${parentPath + '.buying_with_mortgage'}`,
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [
                {
                  $cmp: 'ElRow',
                  props: { gutter: 20 },
                  children: [
                    {
                      $cmp: 'ElCol',
                      props: { span: 12 },
                      children: [
                        {
                          $cmp: 'ElFormItem',
                          props: {
                            label: 'Who arrange your mortgage?',
                            class: 'font-bold w-full',
                          },
                          children: [
                            {
                              $formkit: 'el-radio-group',
                              name: parentPath + '.mortgage_arranged_by',
                              options: [
                                {
                                  label: 'AVRillo',
                                  value: 'avrillo',
                                },
                                {
                                  label: 'Other',
                                  value: 'other',
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  $cmp: 'ElRow',
                  props: { gutter: 20 },
                  children: [
                    {
                      $cmp: 'ElCol',
                      props: { xs: 24, sm: 12 },
                      children: [
                        {
                          $cmp: 'ElRow',
                          props: { gutter: 20 },
                          children: [
                            {
                              if: `$formData.data.${parentPath + '.mortgage_arranged_by'} === 'other'`,
                              $cmp: 'ElCol',
                              props: { xs: 24 },
                              children: [
                                {
                                  $cmp: 'ElFormItem',
                                  props: {
                                    label: 'Please Type Your New Mortgage Lender',
                                    class: 'font-bold',
                                  },
                                  children: [
                                    {
                                      $formkit: 'el-input',
                                      name: parentPath + '.new_mortgage_lender',
                                      inputType: 'text',
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            //             {
            //               $cmp: 'ElRow',
            //               props: { gutter: 20 },
            //               children: [
            //                 {
            //                   if: isFalseCondition(parentPath + '.mortgage_lender_not_in_list'),
            //                   $cmp: 'ElCol',
            //                   props: { xs: 24 },
            //                   children: [
            //                     {
            //                       $cmp: 'ElFormItem',
            //                       props: { label: 'Your New Mortgage Lender' },
            //                       children: [
            //                         {
            //                           $formkit: 'lender-autocomplete',
            //                           name: `${parentPath}.new_mortgage_lender`,
            //                           lenderType: 'purchase',
            //                         },
            //                       ],
            //                     },
            //                   ],
            //                 },
            //               ],
            //             },
            //           ],
            //         },
            //         {
            //           $cmp: 'ElCol',
            //           props: { xs: 24, sm: 12 },
            //           children: [
            //             {
            //               $el: 'div',
            //               children: 'How much will you borrow? (The minimum value must be £10000.)',
            //               attrs: {
            //                 class: 'text-sm font-[500] text-black mb-3 required-label',
            //               },
            //             },
            //             {
            //               $formkit: 'el-input-money',
            //               name: `${parentPath}.mortgage_amount`,
            //               validation: 'required|min:10000',
            //               validationMessages: {
            //                 required: 'This field is required',
            //                 min: 'The minimum value must be £10000.',
            //               },
            //               class: 'w-full mb-6',
            //             },
            //           ],
            //         },
            //         {
            //           $cmp: 'ElCol',
            //           props: { xs: 24 },
            //           children: [...inlineSwitcher(parentPath + '.mortgage_lender_not_in_list', 'My lender not in the list')],
            //         },
            //       ],
            //     },
            //   ],
            // },
            {
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [...estateAgent(parentPath, 'Purchase')],
            },
            {
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [...accessBlock(parentPath)],
            },
            {
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [...alterationsBlock(parentPath)],
            },
            {
              $cmp: 'ElCol',
              props: { span: 24 },
              children: [...flatsBlock(parentPath)],
            },
          ],
        },
      ],
    },
  ]
}
